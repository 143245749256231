<script>
import Layout from "@/router/website-template/main";
import appConfig from "@/app.config";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { createApp } from 'vue'
import App from '@/App.vue'
// import axios from "axios";
// import Swal from "sweetalert2";
/**
 * Pengaduan Component
 */
createApp(App).use(VueReCaptcha, { siteKey: '6LekLIgnAAAAADgz3m6INc8CNHP8F7pMNxu_Q5Qp' })
export default {
  page: {
    title: "Pengaduan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout
  },
  data() {
    return {
      title: "Pengaduan",
      items: [
        {
          text: "Beranda",
          href: "/",
        },
        {
          text: "Pengaduan",
          active: true,
        },
      ],
    };
  },
  mounted() {
    // let self = this;
  },
  methods: {

  },
};
</script>
<style>
  .grecaptcha-badge{
    visibility: visible;
  }
</style>
<template>
  <Layout>
    <div class="row mt-5">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div style="margin-top:50px;">
              <h1 class="text-center">Pengaduan</h1>
              <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-6">
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Nama</label>
                    <input type="text" class="form-control" required>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Email</label>
                    <input type="text" class="form-control" required>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">No Telp</label>
                    <input type="text" class="form-control" required>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Alamat</label>
                    <textarea name="" id="" cols="30" rows="10" class="form-control"></textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="tipe_menu">Pesan <span class="text-danger"> * wajib diisi</span></label>
                    <textarea name="" id="" cols="30" rows="10" class="form-control" required></textarea>
                  </div>
                  <div class="form-group mt-5 text-end">
                    <button class="btn btn-danger">Kirim</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
